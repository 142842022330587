define("ember-promise-helpers/helpers/is-pending", ["exports", "ember-promise-helpers/helpers/await"], function (_exports, _await) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _await.default {
    constructor() {
      super(...arguments);
      this.valueBeforeSettled = true;
    }
    compute(params) {
      const maybePromise = params[0];
      return this.ensureLatestPromise(maybePromise, async promise => {
        try {
          await promise;
          /* eslint-disable-next-line no-empty */
        } catch (_err) {} finally {
          this.setValue(false, maybePromise);
        }
      });
    }
  }
  _exports.default = _default;
});