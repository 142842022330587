define("@html-next/vertical-collection/components/vertical-collection/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.virtualComponents key="id" as |virtualComponent| ~}}
    {{~unbound virtualComponent.upperBound~}}
    {{~#if virtualComponent.isOccludedContent ~}}
      {{{unbound virtualComponent.element}}}
    {{~else~}}
      {{~yield virtualComponent.content virtualComponent.index ~}}
    {{~/if~}}
    {{~unbound virtualComponent.lowerBound~}}
  {{~/each}}
  
  {{#if this.shouldYieldToInverse}}
    {{yield to="inverse"}}
  {{/if}}
  
  */
  {
    "id": "JTfU4LBg",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"virtualComponents\"]]],null]],null],\"id\",[[[1,[28,[35,2],[[30,1,[\"upperBound\"]]],null]],[41,[30,1,[\"isOccludedContent\"]],[[[2,[28,[37,2],[[30,1,[\"element\"]]],null]]],[]],[[[18,2,[[30,1,[\"content\"]],[30,1,[\"index\"]]]]],[]]],[1,[28,[35,2],[[30,1,[\"lowerBound\"]]],null]]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"shouldYieldToInverse\"]],[[[1,\"  \"],[18,3,null],[1,\"\\n\"]],[]],null]],[\"virtualComponent\",\"&default\",\"&else\"],false,[\"each\",\"-track-array\",\"unbound\",\"if\",\"yield\"]]",
    "moduleName": "@html-next/vertical-collection/components/vertical-collection/template.hbs",
    "isStrictMode": false
  });
});