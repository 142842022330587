define("ember-tooltips/templates/components/ember-tooltip-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "70uK+aMq",
    "block": "[[[41,[30,0,[\"_awaitingTooltipElementRendered\"]],[[[1,\"  \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"_shouldRenderContent\"]],[[[41,[48,[30,1]],[[[1,\"        \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"text\"]]],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[40,[[[1,\"    \"],[10,0],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"        \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"text\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,5],[[30,0,[\"_renderElement\"]]],null]]],[]]]],[\"&default\"],false,[\"if\",\"div\",\"has-block\",\"yield\",\"in-element\",\"-in-el-null\"]]",
    "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs",
    "isStrictMode": false
  });
});