define("@glimmer/opcode-compiler", ["exports", "@glimmer/util", "@glimmer/debug", "@glimmer/vm", "@glimmer/encoder", "@glimmer/wire-format", "@glimmer/manager", "@glimmer/global-context"], function (_exports, _util, _debug, _vm, _encoder, _wireFormat, _manager, _globalContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WrappedBuilder = _exports.StdLib = _exports.MINIMAL_CAPABILITIES = _exports.EMPTY_BLOCKS = _exports.DEFAULT_CAPABILITIES = _exports.CompileTimeCompilationContextImpl = void 0;
  _exports.compilable = compilable;
  _exports.compileStatements = compileStatements;
  _exports.compileStd = compileStd;
  _exports.debugCompiler = void 0;
  _exports.invokeStaticBlock = InvokeStaticBlock;
  _exports.invokeStaticBlockWithStack = InvokeStaticBlockWithStack;
  _exports.meta = meta;
  _exports.programCompilationContext = programCompilationContext;
  _exports.templateCacheCounters = void 0;
  _exports.templateCompilationContext = templateCompilationContext;
  _exports.templateFactory = templateFactory;
  let debugCompiler = _exports.debugCompiler = void 0;
  function isGetLikeTuple(opcode) {
    return Array.isArray(opcode) && opcode.length === 2;
  }
  function makeResolutionTypeVerifier(typeToVerify) {
    return opcode => {
      if (!isGetLikeTuple(opcode)) return false;
      let type = opcode[0];
      return type === _wireFormat.SexpOpcodes.GetStrictKeyword || type === _wireFormat.SexpOpcodes.GetLexicalSymbol || type === typeToVerify;
    };
  }
  const isGetFreeComponent = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsComponentHead);
  const isGetFreeModifier = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsModifierHead);
  const isGetFreeHelper = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsHelperHead);
  const isGetFreeComponentOrHelper = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsComponentOrHelperHead);
  const isGetFreeOptionalHelper = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsHelperHeadOrThisFallback);
  function isGetFreeDeprecatedHelper(opcode) {
    return Array.isArray(opcode) && opcode[0] === _wireFormat.SexpOpcodes.GetFreeAsDeprecatedHelperHeadOrThisFallback;
  }
  const isGetFreeOptionalComponentOrHelper = makeResolutionTypeVerifier(_wireFormat.SexpOpcodes.GetFreeAsComponentOrHelperHeadOrThisFallback);
  function assertResolverInvariants(meta) {
    if (false /* DEBUG */) {
      if (!meta.upvars) {
        throw new Error('Attempted to resolve a component, helper, or modifier, but no free vars were found');
      }
      if (!meta.owner) {
        throw new Error('Attempted to resolve a component, helper, or modifier, but no owner was associated with the template it was being resolved from');
      }
    }
    return meta;
  }

  /**
   * <Foo/>
   * <Foo></Foo>
   * <Foo @arg={{true}} />
   */
  function resolveComponent(resolver, constants, meta, _ref) {
    let [, expr, then] = _ref;
    (0, _util.assert)(isGetFreeComponent(expr), 'Attempted to resolve a component with incorrect opcode');
    let type = expr[0];
    if (false /* DEBUG */ && expr[0] === _wireFormat.SexpOpcodes.GetStrictKeyword) {
      throw new Error(`Attempted to resolve a component in a strict mode template, but that value was not in scope: ${meta.upvars[expr[1]] ?? '{unknown variable}'}`);
    }
    if (type === _wireFormat.SexpOpcodes.GetLexicalSymbol) {
      let {
        scopeValues,
        owner
      } = meta;
      let definition = (0, _util.expect)(scopeValues, 'BUG: scopeValues must exist if template symbol is used')[expr[1]];
      then(constants.component(definition, (0, _util.expect)(owner, 'BUG: expected owner when resolving component definition')));
    } else {
      let {
        upvars,
        owner
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let definition = resolver.lookupComponent(name, owner);
      if (false /* DEBUG */ && (typeof definition !== 'object' || definition === null)) {
        throw new Error(`Attempted to resolve \`${name}\`, which was expected to be a component, but nothing was found.`);
      }
      then(constants.resolvedComponent(definition, name));
    }
  }

  /**
   * (helper)
   * (helper arg)
   */
  function resolveHelper(resolver, constants, meta, _ref2) {
    let [, expr, then] = _ref2;
    (0, _util.assert)(isGetFreeHelper(expr), 'Attempted to resolve a helper with incorrect opcode');
    let type = expr[0];
    if (type === _wireFormat.SexpOpcodes.GetLexicalSymbol) {
      let {
        scopeValues
      } = meta;
      let definition = (0, _util.expect)(scopeValues, 'BUG: scopeValues must exist if template symbol is used')[expr[1]];
      then(constants.helper(definition));
    } else if (type === _wireFormat.SexpOpcodes.GetStrictKeyword) {
      then(lookupBuiltInHelper(expr, resolver, meta, constants, 'helper'));
    } else {
      let {
        upvars,
        owner
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let helper = resolver.lookupHelper(name, owner);
      if (false /* DEBUG */ && helper === null) {
        throw new Error(`Attempted to resolve \`${name}\`, which was expected to be a helper, but nothing was found.`);
      }
      then(constants.helper(helper, name));
    }
  }

  /**
   * <div {{modifier}}/>
   * <div {{modifier arg}}/>
   * <Foo {{modifier}}/>
   */
  function resolveModifier(resolver, constants, meta, _ref3) {
    let [, expr, then] = _ref3;
    (0, _util.assert)(isGetFreeModifier(expr), 'Attempted to resolve a modifier with incorrect opcode');
    let type = expr[0];
    if (type === _wireFormat.SexpOpcodes.GetLexicalSymbol) {
      let {
        scopeValues
      } = meta;
      let definition = (0, _util.expect)(scopeValues, 'BUG: scopeValues must exist if template symbol is used')[expr[1]];
      then(constants.modifier(definition));
    } else if (type === _wireFormat.SexpOpcodes.GetStrictKeyword) {
      let {
        upvars
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let modifier = resolver.lookupBuiltInModifier(name);
      if (false /* DEBUG */ && modifier === null) {
        throw new Error(`Attempted to resolve a modifier in a strict mode template, but it was not in scope: ${name}`);
      }
      then(constants.modifier(modifier, name));
    } else {
      let {
        upvars,
        owner
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let modifier = resolver.lookupModifier(name, owner);
      if (false /* DEBUG */ && modifier === null) {
        throw new Error(`Attempted to resolve \`${name}\`, which was expected to be a modifier, but nothing was found.`);
      }
      then(constants.modifier(modifier, name));
    }
  }

  /**
   * {{component-or-helper arg}}
   */
  function resolveComponentOrHelper(resolver, constants, meta, _ref4) {
    let [, expr, {
      ifComponent,
      ifHelper
    }] = _ref4;
    (0, _util.assert)(isGetFreeComponentOrHelper(expr), 'Attempted to resolve a component or helper with incorrect opcode');
    let type = expr[0];
    if (type === _wireFormat.SexpOpcodes.GetLexicalSymbol) {
      let {
        scopeValues,
        owner
      } = meta;
      let definition = (0, _util.expect)(scopeValues, 'BUG: scopeValues must exist if template symbol is used')[expr[1]];
      let component = constants.component(definition, (0, _util.expect)(owner, 'BUG: expected owner when resolving component definition'), true);
      if (component !== null) {
        ifComponent(component);
        return;
      }
      let helper = constants.helper(definition, null, true);
      if (false /* DEBUG */ && helper === null) {
        throw new Error(`Attempted to use a value as either a component or helper, but it did not have a component manager or helper manager associated with it. The value was: ${(0, _util.debugToString)(definition)}`);
      }
      ifHelper((0, _util.expect)(helper, 'BUG: helper must exist'));
    } else if (type === _wireFormat.SexpOpcodes.GetStrictKeyword) {
      ifHelper(lookupBuiltInHelper(expr, resolver, meta, constants, 'component or helper'));
    } else {
      let {
        upvars,
        owner
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let definition = resolver.lookupComponent(name, owner);
      if (definition !== null) {
        ifComponent(constants.resolvedComponent(definition, name));
      } else {
        let helper = resolver.lookupHelper(name, owner);
        if (false /* DEBUG */ && helper === null) {
          throw new Error(`Attempted to resolve \`${name}\`, which was expected to be a component or helper, but nothing was found.`);
        }
        ifHelper(constants.helper(helper, name));
      }
    }
  }

  /**
   * <Foo @arg={{helper}}>
   */
  function resolveOptionalHelper(resolver, constants, meta, _ref5) {
    let [, expr, {
      ifHelper
    }] = _ref5;
    (0, _util.assert)(isGetFreeOptionalHelper(expr) || isGetFreeDeprecatedHelper(expr), 'Attempted to resolve a helper with incorrect opcode');
    let {
      upvars,
      owner
    } = assertResolverInvariants(meta);
    let name = (0, _util.unwrap)(upvars[expr[1]]);
    let helper = resolver.lookupHelper(name, owner);
    if (helper) {
      ifHelper(constants.helper(helper, name), name, meta.moduleName);
    }
  }

  /**
   * {{maybeHelperOrComponent}}
   */
  function resolveOptionalComponentOrHelper(resolver, constants, meta, _ref6) {
    let [, expr, {
      ifComponent,
      ifHelper,
      ifValue
    }] = _ref6;
    (0, _util.assert)(isGetFreeOptionalComponentOrHelper(expr), 'Attempted to resolve an optional component or helper with incorrect opcode');
    let type = expr[0];
    if (type === _wireFormat.SexpOpcodes.GetLexicalSymbol) {
      let {
        scopeValues,
        owner
      } = meta;
      let definition = (0, _util.expect)(scopeValues, 'BUG: scopeValues must exist if template symbol is used')[expr[1]];
      if (typeof definition !== 'function' && (typeof definition !== 'object' || definition === null)) {
        // The value is not an object, so it can't be a component or helper.
        ifValue(constants.value(definition));
        return;
      }
      let component = constants.component(definition, (0, _util.expect)(owner, 'BUG: expected owner when resolving component definition'), true);
      if (component !== null) {
        ifComponent(component);
        return;
      }
      let helper = constants.helper(definition, null, true);
      if (helper !== null) {
        ifHelper(helper);
        return;
      }
      ifValue(constants.value(definition));
    } else if (type === _wireFormat.SexpOpcodes.GetStrictKeyword) {
      ifHelper(lookupBuiltInHelper(expr, resolver, meta, constants, 'value'));
    } else {
      let {
        upvars,
        owner
      } = assertResolverInvariants(meta);
      let name = (0, _util.unwrap)(upvars[expr[1]]);
      let definition = resolver.lookupComponent(name, owner);
      if (definition !== null) {
        ifComponent(constants.resolvedComponent(definition, name));
        return;
      }
      let helper = resolver.lookupHelper(name, owner);
      if (helper !== null) {
        ifHelper(constants.helper(helper, name));
      }
    }
  }
  function lookupBuiltInHelper(expr, resolver, meta, constants, type) {
    let {
      upvars
    } = assertResolverInvariants(meta);
    let name = (0, _util.unwrap)(upvars[expr[1]]);
    let helper = resolver.lookupBuiltInHelper(name);
    if (false /* DEBUG */ && helper === null) {
      // Keyword helper did not exist, which means that we're attempting to use a
      // value of some kind that is not in scope
      throw new Error(`Attempted to resolve a ${type} in a strict mode template, but that value was not in scope: ${meta.upvars[expr[1]] ?? '{unknown variable}'}`);
    }
    return constants.helper(helper, name);
  }
  const HighLevelResolutionOpcodes = {
    Modifier: 1003,
    Component: 1004,
    Helper: 1005,
    OptionalHelper: 1006,
    ComponentOrHelper: 1007,
    OptionalComponentOrHelper: 1008,
    Free: 1009,
    Local: 1010,
    TemplateLocal: 1011
  };
  const HighLevelBuilderOpcodes = {
    Label: 1000,
    StartLabels: 1001,
    StopLabels: 1002,
    Start: 1000,
    End: 1002
  };
  const HighLevelOperands = {
    Label: 1,
    IsStrictMode: 2,
    DebugSymbols: 3,
    Block: 4,
    StdLib: 5,
    NonSmallInt: 6,
    SymbolTable: 7,
    Layout: 8
  };
  function labelOperand(value) {
    return {
      type: HighLevelOperands.Label,
      value
    };
  }
  function debugSymbolsOperand() {
    return {
      type: HighLevelOperands.DebugSymbols,
      value: undefined
    };
  }
  function isStrictMode() {
    return {
      type: HighLevelOperands.IsStrictMode,
      value: undefined
    };
  }
  function blockOperand(value) {
    return {
      type: HighLevelOperands.Block,
      value
    };
  }
  function stdlibOperand(value) {
    return {
      type: HighLevelOperands.StdLib,
      value
    };
  }
  function nonSmallIntOperand(value) {
    (0, _util.assert)(!(0, _util.isSmallInt)(value), 'Attempted to make a operand for an int that was not a small int, you should encode this as an immediate');
    return {
      type: HighLevelOperands.NonSmallInt,
      value
    };
  }
  function symbolTableOperand(value) {
    return {
      type: HighLevelOperands.SymbolTable,
      value
    };
  }
  function layoutOperand(value) {
    return {
      type: HighLevelOperands.Layout,
      value
    };
  }
  class Labels {
    constructor() {
      this.labels = (0, _util.dict)();
      this.targets = [];
    }
    label(name, index) {
      this.labels[name] = index;
    }
    target(at, target) {
      this.targets.push({
        at,
        target
      });
    }
    patch(heap) {
      let {
        targets,
        labels
      } = this;
      for (const {
        at,
        target
      } of targets) {
        let address = labels[target] - at;
        (0, _util.assert)(heap.getbyaddr(at) === -1, 'Expected heap to contain a placeholder, but it did not');
        heap.setbyaddr(at, address);
      }
    }
  }
  function encodeOp(encoder, constants, resolver, meta, op) {
    if (isBuilderOpcode(op[0])) {
      let [type, ...operands] = op;
      encoder.push(constants, type, ...operands);
    } else {
      switch (op[0]) {
        case HighLevelBuilderOpcodes.Label:
          return encoder.label(op[1]);
        case HighLevelBuilderOpcodes.StartLabels:
          return encoder.startLabels();
        case HighLevelBuilderOpcodes.StopLabels:
          return encoder.stopLabels();
        case HighLevelResolutionOpcodes.Component:
          return resolveComponent(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.Modifier:
          return resolveModifier(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.Helper:
          return resolveHelper(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.ComponentOrHelper:
          return resolveComponentOrHelper(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.OptionalHelper:
          return resolveOptionalHelper(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.OptionalComponentOrHelper:
          return resolveOptionalComponentOrHelper(resolver, constants, meta, op);
        case HighLevelResolutionOpcodes.Local:
          {
            let freeVar = op[1];
            let name = (0, _util.expect)(meta.upvars, 'BUG: attempted to resolve value but no upvars found')[freeVar];
            let andThen = op[2];
            andThen(name, meta.moduleName);
            break;
          }
        case HighLevelResolutionOpcodes.TemplateLocal:
          {
            let [, valueIndex, then] = op;
            let value = (0, _util.expect)(meta.scopeValues, 'BUG: Attempted to gect a template local, but template does not have any')[valueIndex];
            then(constants.value(value));
            break;
          }
        case HighLevelResolutionOpcodes.Free:
          if (false /* DEBUG */) {
            let [, upvarIndex] = op;
            let freeName = (0, _util.expect)(meta.upvars, 'BUG: attempted to resolve value but no upvars found')[upvarIndex];
            throw new Error(`Attempted to resolve a value in a strict mode template, but that value was not in scope: ${freeName}`);
          }
          break;
        default:
          throw new Error(`Unexpected high level opcode ${op[0]}`);
      }
    }
  }
  class EncoderImpl {
    constructor(heap, meta, stdlib) {
      this.labelsStack = new _util.Stack();
      this.encoder = new _encoder.InstructionEncoderImpl([]);
      this.errors = [];
      this.handle = void 0;
      this.heap = heap;
      this.meta = meta;
      this.stdlib = stdlib;
      this.handle = heap.malloc();
    }
    error(error) {
      this.encoder.encode(_vm.Op.Primitive, 0);
      this.errors.push(error);
    }
    commit(size) {
      let handle = this.handle;
      this.heap.pushMachine(_vm.MachineOp.Return);
      this.heap.finishMalloc(handle, size);
      if ((0, _util.isPresentArray)(this.errors)) {
        return {
          errors: this.errors,
          handle
        };
      } else {
        return handle;
      }
    }
    push(constants, type) {
      let {
        heap
      } = this;
      if (false /* DEBUG */ && type > _vm.TYPE_SIZE) {
        throw new Error(`Opcode type over 8-bits. Got ${type}.`);
      }
      let machine = (0, _vm.isMachineOp)(type) ? _vm.MACHINE_MASK : 0;
      let first = type | machine | (arguments.length <= 2 ? 0 : arguments.length - 2) << _vm.ARG_SHIFT;
      heap.pushRaw(first);
      for (let i = 0; i < (arguments.length <= 2 ? 0 : arguments.length - 2); i++) {
        let op = i + 2 < 2 || arguments.length <= i + 2 ? undefined : arguments[i + 2];
        heap.pushRaw(this.operand(constants, op));
      }
    }
    operand(constants, operand) {
      if (typeof operand === 'number') {
        return operand;
      }
      if (typeof operand === 'object' && operand !== null) {
        if (Array.isArray(operand)) {
          return (0, _util.encodeHandle)(constants.array(operand));
        } else {
          switch (operand.type) {
            case HighLevelOperands.Label:
              this.currentLabels.target(this.heap.offset, operand.value);
              return -1;
            case HighLevelOperands.IsStrictMode:
              return (0, _util.encodeHandle)(constants.value(this.meta.isStrictMode));
            case HighLevelOperands.DebugSymbols:
              return (0, _util.encodeHandle)(constants.array(this.meta.evalSymbols || _util.EMPTY_STRING_ARRAY));
            case HighLevelOperands.Block:
              return (0, _util.encodeHandle)(constants.value(compilableBlock(operand.value, this.meta)));
            case HighLevelOperands.StdLib:
              return (0, _util.expect)(this.stdlib, 'attempted to encode a stdlib operand, but the encoder did not have a stdlib. Are you currently building the stdlib?')[operand.value];
            case HighLevelOperands.NonSmallInt:
            case HighLevelOperands.SymbolTable:
            case HighLevelOperands.Layout:
              return constants.value(operand.value);
          }
        }
      }
      return (0, _util.encodeHandle)(constants.value(operand));
    }
    get currentLabels() {
      return (0, _util.expect)(this.labelsStack.current, 'bug: not in a label stack');
    }
    label(name) {
      this.currentLabels.label(name, this.heap.offset + 1);
    }
    startLabels() {
      this.labelsStack.push(new Labels());
    }
    stopLabels() {
      let label = (0, _util.expect)(this.labelsStack.pop(), 'unbalanced push and pop labels');
      label.patch(this.heap);
    }
  }
  function isBuilderOpcode(op) {
    return op < HighLevelBuilderOpcodes.Start;
  }
  class StdLib {
    constructor(main, trustingGuardedAppend, cautiousGuardedAppend, trustingNonDynamicAppend, cautiousNonDynamicAppend) {
      this.main = main;
      this.trustingGuardedAppend = trustingGuardedAppend;
      this.cautiousGuardedAppend = cautiousGuardedAppend;
      this.trustingNonDynamicAppend = trustingNonDynamicAppend;
      this.cautiousNonDynamicAppend = cautiousNonDynamicAppend;
    }
    get 'trusting-append'() {
      return this.trustingGuardedAppend;
    }
    get 'cautious-append'() {
      return this.cautiousGuardedAppend;
    }
    get 'trusting-non-dynamic-append'() {
      return this.trustingNonDynamicAppend;
    }
    get 'cautious-non-dynamic-append'() {
      return this.cautiousNonDynamicAppend;
    }
    getAppend(trusting) {
      return trusting ? this.trustingGuardedAppend : this.cautiousGuardedAppend;
    }
  }
  _exports.StdLib = StdLib;
  class NamedBlocksImpl {
    constructor(blocks) {
      this.names = void 0;
      this.blocks = blocks;
      this.names = blocks ? Object.keys(blocks) : [];
    }
    get(name) {
      if (!this.blocks) return null;
      return this.blocks[name] || null;
    }
    has(name) {
      let {
        blocks
      } = this;
      return blocks !== null && name in blocks;
    }
    with(name, block) {
      let {
        blocks
      } = this;
      if (blocks) {
        return new NamedBlocksImpl((0, _util.assign)({}, blocks, {
          [name]: block
        }));
      } else {
        return new NamedBlocksImpl({
          [name]: block
        });
      }
    }
    get hasAny() {
      return this.blocks !== null;
    }
  }
  const EMPTY_BLOCKS = _exports.EMPTY_BLOCKS = new NamedBlocksImpl(null);
  function namedBlocks(blocks) {
    if (blocks === null) {
      return EMPTY_BLOCKS;
    }
    let out = (0, _util.dict)();
    let [keys, values] = blocks;
    for (const [i, key] of (0, _util.enumerate)(keys)) {
      out[key] = (0, _util.unwrap)(values[i]);
    }
    return new NamedBlocksImpl(out);
  }

  /**
   * Push a reference onto the stack corresponding to a statically known primitive
   * @param value A JavaScript primitive (undefined, null, boolean, number or string)
   */
  function PushPrimitiveReference(op, value) {
    PushPrimitive(op, value);
    op(_vm.Op.PrimitiveReference);
  }

  /**
   * Push an encoded representation of a JavaScript primitive on the stack
   *
   * @param value A JavaScript primitive (undefined, null, boolean, number or string)
   */
  function PushPrimitive(op, primitive) {
    let p = primitive;
    if (typeof p === 'number') {
      p = (0, _util.isSmallInt)(p) ? (0, _util.encodeImmediate)(p) : nonSmallIntOperand(p);
    }
    op(_vm.Op.Primitive, p);
  }

  /**
   * Invoke a foreign function (a "helper") based on a statically known handle
   *
   * @param op The op creation function
   * @param handle A handle
   * @param positional An optional list of expressions to compile
   * @param named An optional list of named arguments (name + expression) to compile
   */
  function Call(op, handle, positional, named) {
    op(_vm.MachineOp.PushFrame);
    SimpleArgs(op, positional, named, false);
    op(_vm.Op.Helper, handle);
    op(_vm.MachineOp.PopFrame);
    op(_vm.Op.Fetch, _vm.$v0);
  }

  /**
   * Invoke a foreign function (a "helper") based on a dynamically loaded definition
   *
   * @param op The op creation function
   * @param positional An optional list of expressions to compile
   * @param named An optional list of named arguments (name + expression) to compile
   */
  function CallDynamic(op, positional, named, append) {
    op(_vm.MachineOp.PushFrame);
    SimpleArgs(op, positional, named, false);
    op(_vm.Op.Dup, _vm.$fp, 1);
    op(_vm.Op.DynamicHelper);
    if (append) {
      op(_vm.Op.Fetch, _vm.$v0);
      append();
      op(_vm.MachineOp.PopFrame);
      op(_vm.Op.Pop, 1);
    } else {
      op(_vm.MachineOp.PopFrame);
      op(_vm.Op.Pop, 1);
      op(_vm.Op.Fetch, _vm.$v0);
    }
  }

  /**
   * Evaluate statements in the context of new dynamic scope entries. Move entries from the
   * stack into named entries in the dynamic scope, then evaluate the statements, then pop
   * the dynamic scope
   *
   * @param names a list of dynamic scope names
   * @param block a function that returns a list of statements to evaluate
   */
  function DynamicScope(op, names, block) {
    op(_vm.Op.PushDynamicScope);
    op(_vm.Op.BindDynamicScope, names);
    block();
    op(_vm.Op.PopDynamicScope);
  }
  function Curry(op, type, definition, positional, named) {
    op(_vm.MachineOp.PushFrame);
    SimpleArgs(op, positional, named, false);
    op(_vm.Op.CaptureArgs);
    expr(op, definition);
    op(_vm.Op.Curry, type, isStrictMode());
    op(_vm.MachineOp.PopFrame);
    op(_vm.Op.Fetch, _vm.$v0);
  }
  class Compilers {
    constructor() {
      this.names = {};
      this.funcs = [];
    }
    add(name, func) {
      this.names[name] = this.funcs.push(func) - 1;
    }
    compile(op, sexp) {
      let name = sexp[0];
      let index = (0, _util.unwrap)(this.names[name]);
      let func = this.funcs[index];
      (0, _util.assert)(!!func, `expected an implementation for ${sexp[0]}`);
      func(op, sexp);
    }
  }
  const EXPRESSIONS = new Compilers();
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Concat, (op, _ref7) => {
    let [, parts] = _ref7;
    for (let part of parts) {
      expr(op, part);
    }
    op(_vm.Op.Concat, parts.length);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Call, (op, _ref8) => {
    let [, expression, positional, named] = _ref8;
    if (isGetFreeHelper(expression)) {
      op(HighLevelResolutionOpcodes.Helper, expression, handle => {
        Call(op, handle, positional, named);
      });
    } else {
      expr(op, expression);
      CallDynamic(op, positional, named);
    }
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Curry, (op, _ref9) => {
    let [, expr, type, positional, named] = _ref9;
    Curry(op, type, expr, positional, named);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetSymbol, (op, _ref10) => {
    let [, sym, path] = _ref10;
    op(_vm.Op.GetVariable, sym);
    withPath(op, path);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetLexicalSymbol, (op, _ref11) => {
    let [, sym, path] = _ref11;
    op(HighLevelResolutionOpcodes.TemplateLocal, sym, handle => {
      op(_vm.Op.ConstantReference, handle);
      withPath(op, path);
    });
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetStrictKeyword, (op, _ref12) => {
    let [, sym, _path] = _ref12;
    op(HighLevelResolutionOpcodes.Free, sym, _handle => {
      // TODO: Implement in strict mode
    });
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetFreeAsComponentOrHelperHeadOrThisFallback, () => {
    // TODO: The logic for this opcode currently exists in STATEMENTS.Append, since
    // we want different wrapping logic depending on if we are invoking a component,
    // helper, or {{this}} fallback. Eventually we fix the opcodes so that we can
    // traverse the subexpression tree like normal in this location.
    throw new Error('unimplemented opcode');
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetFreeAsHelperHeadOrThisFallback, (op, expr) => {
    // <div id={{baz}}>

    op(HighLevelResolutionOpcodes.Local, expr[1], _name => {
      op(HighLevelResolutionOpcodes.OptionalHelper, expr, {
        ifHelper: handle => {
          Call(op, handle, null, null);
        }
      });
    });
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetFreeAsDeprecatedHelperHeadOrThisFallback, (op, expr) => {
    // <Foo @bar={{baz}}>

    op(HighLevelResolutionOpcodes.Local, expr[1], _name => {
      op(HighLevelResolutionOpcodes.OptionalHelper, expr, {
        ifHelper: (handle, name, moduleName) => {
          (false && (0, _globalContext.assert)(expr[2] && expr[2].length === 1, '[BUG] Missing argument name'));
          let arg = expr[2][0];
          (false && !(false) && (0, _globalContext.deprecate)(`The \`${name}\` helper was used in the \`${moduleName}\` template as \`${arg}={{${name}}}\`. ` + `This is ambigious between wanting the \`${arg}\` argument to be the \`${name}\` helper itself, ` + `or the result of invoking the \`${name}\` helper (current behavior). ` + `This implicit invocation behavior has been deprecated.\n\n` + `Instead, please explicitly invoke the helper with parenthesis, i.e. \`${arg}={{(${name})}}\`.\n\n` + `Note: the parenthesis are only required in this exact scenario where an ambiguity is present – where ` + `\`${name}\` referes to a global helper (as opposed to a local variable), AND ` + `the \`${name}\` helper invocation does not take any arguments, AND ` + `this occurs in a named argument position of a component invocation.\n\n` + `We expect this combination to be quite rare, as most helpers require at least one argument. ` + `There is no need to refactor helper invocations in cases where this deprecation was not triggered.`, false, {
            id: 'argument-less-helper-paren-less-invocation'
          }));
          Call(op, handle, null, null);
        }
      });
    });
  });
  function withPath(op, path) {
    if (path === undefined || path.length === 0) return;
    for (let i = 0; i < path.length; i++) {
      op(_vm.Op.GetProperty, path[i]);
    }
  }
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Undefined, op => PushPrimitiveReference(op, undefined));
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.HasBlock, (op, _ref13) => {
    let [, block] = _ref13;
    expr(op, block);
    op(_vm.Op.HasBlock);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.HasBlockParams, (op, _ref14) => {
    let [, block] = _ref14;
    expr(op, block);
    op(_vm.Op.SpreadBlock);
    op(_vm.Op.CompileBlock);
    op(_vm.Op.HasBlockParams);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.IfInline, (op, _ref15) => {
    let [, condition, truthy, falsy] = _ref15;
    // Push in reverse order
    expr(op, falsy);
    expr(op, truthy);
    expr(op, condition);
    op(_vm.Op.IfInline);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Not, (op, _ref16) => {
    let [, value] = _ref16;
    expr(op, value);
    op(_vm.Op.Not);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.GetDynamicVar, (op, _ref17) => {
    let [, expression] = _ref17;
    expr(op, expression);
    op(_vm.Op.GetDynamicVar);
  });
  EXPRESSIONS.add(_wireFormat.SexpOpcodes.Log, (op, _ref18) => {
    let [, positional] = _ref18;
    op(_vm.MachineOp.PushFrame);
    SimpleArgs(op, positional, null, false);
    op(_vm.Op.Log);
    op(_vm.MachineOp.PopFrame);
    op(_vm.Op.Fetch, _vm.$v0);
  });
  function expr(op, expression) {
    if (Array.isArray(expression)) {
      EXPRESSIONS.compile(op, expression);
    } else {
      PushPrimitive(op, expression);
      op(_vm.Op.PrimitiveReference);
    }
  }

  /**
   * Compile arguments, pushing an Arguments object onto the stack.
   *
   * @param args.params
   * @param args.hash
   * @param args.blocks
   * @param args.atNames
   */
  function CompileArgs(op, positional, named, blocks, atNames) {
    let blockNames = blocks.names;
    for (const name of blockNames) {
      PushYieldableBlock(op, blocks.get(name));
    }
    let count = CompilePositional(op, positional);
    let flags = count << 4;
    if (atNames) flags |= 0b1000;
    if (blocks) {
      flags |= 0b111;
    }
    let names = _util.EMPTY_ARRAY;
    if (named) {
      names = named[0];
      let val = named[1];
      for (let i = 0; i < val.length; i++) {
        expr(op, val[i]);
      }
    }
    op(_vm.Op.PushArgs, names, blockNames, flags);
  }
  function SimpleArgs(op, positional, named, atNames) {
    if (positional === null && named === null) {
      op(_vm.Op.PushEmptyArgs);
      return;
    }
    let count = CompilePositional(op, positional);
    let flags = count << 4;
    if (atNames) flags |= 0b1000;
    let names = _util.EMPTY_STRING_ARRAY;
    if (named) {
      names = named[0];
      let val = named[1];
      for (let i = 0; i < val.length; i++) {
        expr(op, val[i]);
      }
    }
    op(_vm.Op.PushArgs, names, _util.EMPTY_STRING_ARRAY, flags);
  }

  /**
   * Compile an optional list of positional arguments, which pushes each argument
   * onto the stack and returns the number of parameters compiled
   *
   * @param positional an optional list of positional arguments
   */
  function CompilePositional(op, positional) {
    if (positional === null) return 0;
    for (let i = 0; i < positional.length; i++) {
      expr(op, positional[i]);
    }
    return positional.length;
  }
  function meta(layout) {
    let [, symbols,, upvars] = layout.block;
    return {
      evalSymbols: evalSymbols(layout),
      upvars: upvars,
      scopeValues: layout.scope?.() ?? null,
      isStrictMode: layout.isStrictMode,
      moduleName: layout.moduleName,
      owner: layout.owner,
      size: symbols.length
    };
  }
  function evalSymbols(layout) {
    let {
      block
    } = layout;
    let [, symbols, hasEval] = block;
    return hasEval ? symbols : null;
  }

  /**
   * Yield to a block located at a particular symbol location.
   *
   * @param to the symbol containing the block to yield to
   * @param params optional block parameters to yield to the block
   */
  function YieldBlock(op, to, positional) {
    SimpleArgs(op, positional, null, true);
    op(_vm.Op.GetBlock, to);
    op(_vm.Op.SpreadBlock);
    op(_vm.Op.CompileBlock);
    op(_vm.Op.InvokeYield);
    op(_vm.Op.PopScope);
    op(_vm.MachineOp.PopFrame);
  }

  /**
   * Push an (optional) yieldable block onto the stack. The yieldable block must be known
   * statically at compile time.
   *
   * @param block An optional Compilable block
   */
  function PushYieldableBlock(op, block) {
    PushSymbolTable(op, block && block[1]);
    op(_vm.Op.PushBlockScope);
    PushCompilable(op, block);
  }

  /**
   * Invoke a block that is known statically at compile time.
   *
   * @param block a Compilable block
   */
  function InvokeStaticBlock(op, block) {
    op(_vm.MachineOp.PushFrame);
    PushCompilable(op, block);
    op(_vm.Op.CompileBlock);
    op(_vm.MachineOp.InvokeVirtual);
    op(_vm.MachineOp.PopFrame);
  }

  /**
   * Invoke a static block, preserving some number of stack entries for use in
   * updating.
   *
   * @param block A compilable block
   * @param callerCount A number of stack entries to preserve
   */
  function InvokeStaticBlockWithStack(op, block, callerCount) {
    let parameters = block[1];
    let calleeCount = parameters.length;
    let count = Math.min(callerCount, calleeCount);
    if (count === 0) {
      InvokeStaticBlock(op, block);
      return;
    }
    op(_vm.MachineOp.PushFrame);
    if (count) {
      op(_vm.Op.ChildScope);
      for (let i = 0; i < count; i++) {
        op(_vm.Op.Dup, _vm.$fp, callerCount - i);
        op(_vm.Op.SetVariable, parameters[i]);
      }
    }
    PushCompilable(op, block);
    op(_vm.Op.CompileBlock);
    op(_vm.MachineOp.InvokeVirtual);
    if (count) {
      op(_vm.Op.PopScope);
    }
    op(_vm.MachineOp.PopFrame);
  }
  function PushSymbolTable(op, parameters) {
    if (parameters !== null) {
      op(_vm.Op.PushSymbolTable, symbolTableOperand({
        parameters
      }));
    } else {
      PushPrimitive(op, null);
    }
  }
  function PushCompilable(op, _block) {
    if (_block === null) {
      PushPrimitive(op, null);
    } else {
      op(_vm.Op.Constant, blockOperand(_block));
    }
  }
  function SwitchCases(op, bootstrap, matcher) {
    // Setup the switch DSL
    let clauses = [];
    let count = 0;
    function when(match, callback) {
      clauses.push({
        match,
        callback,
        label: `CLAUSE${count++}`
      });
    }

    // Call the callback
    matcher(when);

    // Emit the opcodes for the switch
    op(_vm.Op.Enter, 1);
    bootstrap();
    op(HighLevelBuilderOpcodes.StartLabels);

    // First, emit the jump opcodes. We don't need a jump for the last
    // opcode, since it bleeds directly into its clause.
    for (let clause of clauses.slice(0, -1)) {
      op(_vm.Op.JumpEq, labelOperand(clause.label), clause.match);
    }

    // Enumerate the clauses in reverse order. Earlier matches will
    // require fewer checks.
    for (let i = clauses.length - 1; i >= 0; i--) {
      let clause = (0, _util.unwrap)(clauses[i]);
      op(HighLevelBuilderOpcodes.Label, clause.label);
      op(_vm.Op.Pop, 1);
      clause.callback();

      // The first match is special: it is placed directly before the END
      // label, so no additional jump is needed at the end of it.
      if (i !== 0) {
        op(_vm.MachineOp.Jump, labelOperand('END'));
      }
    }
    op(HighLevelBuilderOpcodes.Label, 'END');
    op(HighLevelBuilderOpcodes.StopLabels);
    op(_vm.Op.Exit);
  }

  /**
   * A convenience for pushing some arguments on the stack and
   * running some code if the code needs to be re-executed during
   * updating execution if some of the arguments have changed.
   *
   * # Initial Execution
   *
   * The `args` function should push zero or more arguments onto
   * the stack and return the number of arguments pushed.
   *
   * The `body` function provides the instructions to execute both
   * during initial execution and during updating execution.
   *
   * Internally, this function starts by pushing a new frame, so
   * that the body can return and sets the return point ($ra) to
   * the ENDINITIAL label.
   *
   * It then executes the `args` function, which adds instructions
   * responsible for pushing the arguments for the block to the
   * stack. These arguments will be restored to the stack before
   * updating execution.
   *
   * Next, it adds the Enter opcode, which marks the current position
   * in the DOM, and remembers the current $pc (the next instruction)
   * as the first instruction to execute during updating execution.
   *
   * Next, it runs `body`, which adds the opcodes that should
   * execute both during initial execution and during updating execution.
   * If the `body` wishes to finish early, it should Jump to the
   * `FINALLY` label.
   *
   * Next, it adds the FINALLY label, followed by:
   *
   * - the Exit opcode, which finalizes the marked DOM started by the
   *   Enter opcode.
   * - the Return opcode, which returns to the current return point
   *   ($ra).
   *
   * Finally, it adds the ENDINITIAL label followed by the PopFrame
   * instruction, which restores $fp, $sp and $ra.
   *
   * # Updating Execution
   *
   * Updating execution for this `replayable` occurs if the `body` added an
   * assertion, via one of the `JumpIf`, `JumpUnless` or `AssertSame` opcodes.
   *
   * If, during updating executon, the assertion fails, the initial VM is
   * restored, and the stored arguments are pushed onto the stack. The DOM
   * between the starting and ending markers is cleared, and the VM's cursor
   * is set to the area just cleared.
   *
   * The return point ($ra) is set to -1, the exit instruction.
   *
   * Finally, the $pc is set to to the instruction saved off by the
   * Enter opcode during initial execution, and execution proceeds as
   * usual.
   *
   * The only difference is that when a `Return` instruction is
   * encountered, the program jumps to -1 rather than the END label,
   * and the PopFrame opcode is not needed.
   */
  function Replayable(op, args, body) {
    // Start a new label frame, to give END and RETURN
    // a unique meaning.

    op(HighLevelBuilderOpcodes.StartLabels);
    op(_vm.MachineOp.PushFrame);

    // If the body invokes a block, its return will return to
    // END. Otherwise, the return in RETURN will return to END.
    op(_vm.MachineOp.ReturnTo, labelOperand('ENDINITIAL'));

    // Push the arguments onto the stack. The args() function
    // tells us how many stack elements to retain for re-execution
    // when updating.
    let count = args();

    // Start a new updating closure, remembering `count` elements
    // from the stack. Everything after this point, and before END,
    // will execute both initially and to update the block.
    //
    // The enter and exit opcodes also track the area of the DOM
    // associated with this block. If an assertion inside the block
    // fails (for example, the test value changes from true to false
    // in an #if), the DOM is cleared and the program is re-executed,
    // restoring `count` elements to the stack and executing the
    // instructions between the enter and exit.
    op(_vm.Op.Enter, count);

    // Evaluate the body of the block. The body of the block may
    // return, which will jump execution to END during initial
    // execution, and exit the updating routine.
    body();

    // All execution paths in the body should run the FINALLY once
    // they are done. It is executed both during initial execution
    // and during updating execution.
    op(HighLevelBuilderOpcodes.Label, 'FINALLY');

    // Finalize the DOM.
    op(_vm.Op.Exit);

    // In initial execution, this is a noop: it returns to the
    // immediately following opcode. In updating execution, this
    // exits the updating routine.
    op(_vm.MachineOp.Return);

    // Cleanup code for the block. Runs on initial execution
    // but not on updating.
    op(HighLevelBuilderOpcodes.Label, 'ENDINITIAL');
    op(_vm.MachineOp.PopFrame);
    op(HighLevelBuilderOpcodes.StopLabels);
  }

  /**
   * A specialized version of the `replayable` convenience that allows the
   * caller to provide different code based upon whether the item at
   * the top of the stack is true or false.
   *
   * As in `replayable`, the `ifTrue` and `ifFalse` code can invoke `return`.
   *
   * During the initial execution, a `return` will continue execution
   * in the cleanup code, which finalizes the current DOM block and pops
   * the current frame.
   *
   * During the updating execution, a `return` will exit the updating
   * routine, as it can reuse the DOM block and is always only a single
   * frame deep.
   */
  function ReplayableIf(op, args, ifTrue, ifFalse) {
    return Replayable(op, args, () => {
      // If the conditional is false, jump to the ELSE label.
      op(_vm.Op.JumpUnless, labelOperand('ELSE'));
      // Otherwise, execute the code associated with the true branch.
      ifTrue();
      // We're done, so return. In the initial execution, this runs
      // the cleanup code. In the updating VM, it exits the updating
      // routine.
      op(_vm.MachineOp.Jump, labelOperand('FINALLY'));
      op(HighLevelBuilderOpcodes.Label, 'ELSE');

      // If the conditional is false, and code associatied ith the
      // false branch was provided, execute it. If there was no code
      // associated with the false branch, jumping to the else statement
      // has no other behavior.
      if (ifFalse !== undefined) {
        ifFalse();
      }
    });
  }
  const ATTRS_BLOCK = '&attrs';

  // {{component}}

  // <Component>

  // chokepoint

  function InvokeComponent(op, component, _elementBlock, positional, named, _blocks) {
    let {
      compilable,
      capabilities,
      handle
    } = component;
    let elementBlock = _elementBlock ? [_elementBlock, []] : null;
    let blocks = Array.isArray(_blocks) || _blocks === null ? namedBlocks(_blocks) : _blocks;
    if (compilable) {
      op(_vm.Op.PushComponentDefinition, handle);
      InvokeStaticComponent(op, {
        capabilities: capabilities,
        layout: compilable,
        elementBlock,
        positional,
        named,
        blocks
      });
    } else {
      op(_vm.Op.PushComponentDefinition, handle);
      InvokeNonStaticComponent(op, {
        capabilities: capabilities,
        elementBlock,
        positional,
        named,
        atNames: true,
        blocks
      });
    }
  }
  function InvokeDynamicComponent(op, definition, _elementBlock, positional, named, _blocks, atNames, curried) {
    let elementBlock = _elementBlock ? [_elementBlock, []] : null;
    let blocks = Array.isArray(_blocks) || _blocks === null ? namedBlocks(_blocks) : _blocks;
    Replayable(op, () => {
      expr(op, definition);
      op(_vm.Op.Dup, _vm.$sp, 0);
      return 2;
    }, () => {
      op(_vm.Op.JumpUnless, labelOperand('ELSE'));
      if (curried) {
        op(_vm.Op.ResolveCurriedComponent);
      } else {
        op(_vm.Op.ResolveDynamicComponent, isStrictMode());
      }
      op(_vm.Op.PushDynamicComponentInstance);
      InvokeNonStaticComponent(op, {
        capabilities: true,
        elementBlock,
        positional,
        named,
        atNames,
        blocks
      });
      op(HighLevelBuilderOpcodes.Label, 'ELSE');
    });
  }
  function InvokeStaticComponent(op, _ref19) {
    let {
      capabilities,
      layout,
      elementBlock,
      positional,
      named,
      blocks
    } = _ref19;
    let {
      symbolTable
    } = layout;
    let bailOut = symbolTable.hasEval || (0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.prepareArgs);
    if (bailOut) {
      InvokeNonStaticComponent(op, {
        capabilities,
        elementBlock,
        positional,
        named,
        atNames: true,
        blocks,
        layout
      });
      return;
    }
    op(_vm.Op.Fetch, _vm.$s0);
    op(_vm.Op.Dup, _vm.$sp, 1);
    op(_vm.Op.Load, _vm.$s0);
    op(_vm.MachineOp.PushFrame);

    // Setup arguments
    let {
      symbols
    } = symbolTable;

    // As we push values onto the stack, we store the symbols associated  with them
    // so that we can set them on the scope later on with SetVariable and SetBlock
    let blockSymbols = [];
    let argSymbols = [];
    let argNames = [];

    // First we push the blocks onto the stack
    let blockNames = blocks.names;

    // Starting with the attrs block, if it exists and is referenced in the component
    if (elementBlock !== null) {
      let symbol = symbols.indexOf(ATTRS_BLOCK);
      if (symbol !== -1) {
        PushYieldableBlock(op, elementBlock);
        blockSymbols.push(symbol);
      }
    }

    // Followed by the other blocks, if they exist and are referenced in the component.
    // Also store the index of the associated symbol.
    for (const name of blockNames) {
      let symbol = symbols.indexOf(`&${name}`);
      if (symbol !== -1) {
        PushYieldableBlock(op, blocks.get(name));
        blockSymbols.push(symbol);
      }
    }

    // Next up we have arguments. If the component has the `createArgs` capability,
    // then it wants access to the arguments in JavaScript. We can't know whether
    // or not an argument is used, so we have to give access to all of them.
    if ((0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.createArgs)) {
      // First we push positional arguments
      let count = CompilePositional(op, positional);

      // setup the flags with the count of positionals, and to indicate that atNames
      // are used
      let flags = count << 4;
      flags |= 0b1000;
      let names = _util.EMPTY_STRING_ARRAY;

      // Next, if named args exist, push them all. If they have an associated symbol
      // in the invoked component (e.g. they are used within its template), we push
      // that symbol. If not, we still push the expression as it may be used, and
      // we store the symbol as -1 (this is used later).
      if (named !== null) {
        names = named[0];
        let val = named[1];
        for (let i = 0; i < val.length; i++) {
          let symbol = symbols.indexOf((0, _util.unwrap)(names[i]));
          expr(op, val[i]);
          argSymbols.push(symbol);
        }
      }

      // Finally, push the VM arguments themselves. These args won't need access
      // to blocks (they aren't accessible from userland anyways), so we push an
      // empty array instead of the actual block names.
      op(_vm.Op.PushArgs, names, _util.EMPTY_STRING_ARRAY, flags);

      // And push an extra pop operation to remove the args before we begin setting
      // variables on the local context
      argSymbols.push(-1);
    } else if (named !== null) {
      // If the component does not have the `createArgs` capability, then the only
      // expressions we need to push onto the stack are those that are actually
      // referenced in the template of the invoked component (e.g. have symbols).
      let names = named[0];
      let val = named[1];
      for (let i = 0; i < val.length; i++) {
        let name = (0, _util.unwrap)(names[i]);
        let symbol = symbols.indexOf(name);
        if (symbol !== -1) {
          expr(op, val[i]);
          argSymbols.push(symbol);
          argNames.push(name);
        }
      }
    }
    op(_vm.Op.BeginComponentTransaction, _vm.$s0);
    if ((0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.dynamicScope)) {
      op(_vm.Op.PushDynamicScope);
    }
    if ((0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.createInstance)) {
      op(_vm.Op.CreateComponent, blocks.has('default') | 0, _vm.$s0);
    }
    op(_vm.Op.RegisterComponentDestructor, _vm.$s0);
    if ((0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.createArgs)) {
      op(_vm.Op.GetComponentSelf, _vm.$s0);
    } else {
      op(_vm.Op.GetComponentSelf, _vm.$s0, argNames);
    }

    // Setup the new root scope for the component
    op(_vm.Op.RootScope, symbols.length + 1, Object.keys(blocks).length > 0 ? 1 : 0);

    // Pop the self reference off the stack and set it to the symbol for `this`
    // in the new scope. This is why all subsequent symbols are increased by one.
    op(_vm.Op.SetVariable, 0);

    // Going in reverse, now we pop the args/blocks off the stack, starting with
    // arguments, and assign them to their symbols in the new scope.
    for (const symbol of (0, _util.reverse)(argSymbols)) {
      // for (let i = argSymbols.length - 1; i >= 0; i--) {
      //   let symbol = argSymbols[i];

      if (symbol === -1) {
        // The expression was not bound to a local symbol, it was only pushed to be
        // used with VM args in the javascript side
        op(_vm.Op.Pop, 1);
      } else {
        op(_vm.Op.SetVariable, symbol + 1);
      }
    }

    // if any positional params exist, pop them off the stack as well
    if (positional !== null) {
      op(_vm.Op.Pop, positional.length);
    }

    // Finish up by popping off and assigning blocks
    for (const symbol of (0, _util.reverse)(blockSymbols)) {
      op(_vm.Op.SetBlock, symbol + 1);
    }
    op(_vm.Op.Constant, layoutOperand(layout));
    op(_vm.Op.CompileBlock);
    op(_vm.MachineOp.InvokeVirtual);
    op(_vm.Op.DidRenderLayout, _vm.$s0);
    op(_vm.MachineOp.PopFrame);
    op(_vm.Op.PopScope);
    if ((0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.dynamicScope)) {
      op(_vm.Op.PopDynamicScope);
    }
    op(_vm.Op.CommitComponentTransaction);
    op(_vm.Op.Load, _vm.$s0);
  }
  function InvokeNonStaticComponent(op, _ref20) {
    let {
      capabilities,
      elementBlock,
      positional,
      named,
      atNames,
      blocks: namedBlocks,
      layout
    } = _ref20;
    let bindableBlocks = !!namedBlocks;
    let bindableAtNames = capabilities === true || (0, _manager.hasCapability)(capabilities, _vm.InternalComponentCapabilities.prepareArgs) || !!(named && named[0].length !== 0);
    let blocks = namedBlocks.with('attrs', elementBlock);
    op(_vm.Op.Fetch, _vm.$s0);
    op(_vm.Op.Dup, _vm.$sp, 1);
    op(_vm.Op.Load, _vm.$s0);
    op(_vm.MachineOp.PushFrame);
    CompileArgs(op, positional, named, blocks, atNames);
    op(_vm.Op.PrepareArgs, _vm.$s0);
    invokePreparedComponent(op, blocks.has('default'), bindableBlocks, bindableAtNames, () => {
      if (layout) {
        op(_vm.Op.PushSymbolTable, symbolTableOperand(layout.symbolTable));
        op(_vm.Op.Constant, layoutOperand(layout));
        op(_vm.Op.CompileBlock);
      } else {
        op(_vm.Op.GetComponentLayout, _vm.$s0);
      }
      op(_vm.Op.PopulateLayout, _vm.$s0);
    });
    op(_vm.Op.Load, _vm.$s0);
  }
  function WrappedComponent(op, layout, attrsBlockNumber) {
    op(HighLevelBuilderOpcodes.StartLabels);
    WithSavedRegister(op, _vm.$s1, () => {
      op(_vm.Op.GetComponentTagName, _vm.$s0);
      op(_vm.Op.PrimitiveReference);
      op(_vm.Op.Dup, _vm.$sp, 0);
    });
    op(_vm.Op.JumpUnless, labelOperand('BODY'));
    op(_vm.Op.Fetch, _vm.$s1);
    op(_vm.Op.PutComponentOperations);
    op(_vm.Op.OpenDynamicElement);
    op(_vm.Op.DidCreateElement, _vm.$s0);
    YieldBlock(op, attrsBlockNumber, null);
    op(_vm.Op.FlushElement);
    op(HighLevelBuilderOpcodes.Label, 'BODY');
    InvokeStaticBlock(op, [layout.block[0], []]);
    op(_vm.Op.Fetch, _vm.$s1);
    op(_vm.Op.JumpUnless, labelOperand('END'));
    op(_vm.Op.CloseElement);
    op(HighLevelBuilderOpcodes.Label, 'END');
    op(_vm.Op.Load, _vm.$s1);
    op(HighLevelBuilderOpcodes.StopLabels);
  }
  function invokePreparedComponent(op, hasBlock, bindableBlocks, bindableAtNames, populateLayout) {
    if (populateLayout === void 0) {
      populateLayout = null;
    }
    op(_vm.Op.BeginComponentTransaction, _vm.$s0);
    op(_vm.Op.PushDynamicScope);
    op(_vm.Op.CreateComponent, hasBlock | 0, _vm.$s0);

    // this has to run after createComponent to allow
    // for late-bound layouts, but a caller is free
    // to populate the layout earlier if it wants to
    // and do nothing here.
    if (populateLayout) {
      populateLayout();
    }
    op(_vm.Op.RegisterComponentDestructor, _vm.$s0);
    op(_vm.Op.GetComponentSelf, _vm.$s0);
    op(_vm.Op.VirtualRootScope, _vm.$s0);
    op(_vm.Op.SetVariable, 0);
    op(_vm.Op.SetupForEval, _vm.$s0);
    if (bindableAtNames) op(_vm.Op.SetNamedVariables, _vm.$s0);
    if (bindableBlocks) op(_vm.Op.SetBlocks, _vm.$s0);
    op(_vm.Op.Pop, 1);
    op(_vm.Op.InvokeComponentLayout, _vm.$s0);
    op(_vm.Op.DidRenderLayout, _vm.$s0);
    op(_vm.MachineOp.PopFrame);
    op(_vm.Op.PopScope);
    op(_vm.Op.PopDynamicScope);
    op(_vm.Op.CommitComponentTransaction);
  }
  function InvokeBareComponent(op) {
    op(_vm.Op.Fetch, _vm.$s0);
    op(_vm.Op.Dup, _vm.$sp, 1);
    op(_vm.Op.Load, _vm.$s0);
    op(_vm.MachineOp.PushFrame);
    op(_vm.Op.PushEmptyArgs);
    op(_vm.Op.PrepareArgs, _vm.$s0);
    invokePreparedComponent(op, false, false, true, () => {
      op(_vm.Op.GetComponentLayout, _vm.$s0);
      op(_vm.Op.PopulateLayout, _vm.$s0);
    });
    op(_vm.Op.Load, _vm.$s0);
  }
  function WithSavedRegister(op, register, block) {
    op(_vm.Op.Fetch, register);
    block();
    op(_vm.Op.Load, register);
  }
  function main(op) {
    op(_vm.Op.Main, _vm.$s0);
    invokePreparedComponent(op, false, false, true);
  }

  /**
   * Append content to the DOM. This standard function triages content and does the
   * right thing based upon whether it's a string, safe string, component, fragment
   * or node.
   *
   * @param trusting whether to interpolate a string as raw HTML (corresponds to
   * triple curlies)
   */
  function StdAppend(op, trusting, nonDynamicAppend) {
    SwitchCases(op, () => op(_vm.Op.ContentType), when => {
      when(_vm.ContentType.String, () => {
        if (trusting) {
          op(_vm.Op.AssertSame);
          op(_vm.Op.AppendHTML);
        } else {
          op(_vm.Op.AppendText);
        }
      });
      if (typeof nonDynamicAppend === 'number') {
        when(_vm.ContentType.Component, () => {
          op(_vm.Op.ResolveCurriedComponent);
          op(_vm.Op.PushDynamicComponentInstance);
          InvokeBareComponent(op);
        });
        when(_vm.ContentType.Helper, () => {
          CallDynamic(op, null, null, () => {
            op(_vm.MachineOp.InvokeStatic, nonDynamicAppend);
          });
        });
      } else {
        // when non-dynamic, we can no longer call the value (potentially because we've already called it)
        // this prevents infinite loops. We instead coerce the value, whatever it is, into the DOM.
        when(_vm.ContentType.Component, () => {
          op(_vm.Op.AppendText);
        });
        when(_vm.ContentType.Helper, () => {
          op(_vm.Op.AppendText);
        });
      }
      when(_vm.ContentType.SafeString, () => {
        op(_vm.Op.AssertSame);
        op(_vm.Op.AppendSafeHTML);
      });
      when(_vm.ContentType.Fragment, () => {
        op(_vm.Op.AssertSame);
        op(_vm.Op.AppendDocumentFragment);
      });
      when(_vm.ContentType.Node, () => {
        op(_vm.Op.AssertSame);
        op(_vm.Op.AppendNode);
      });
    });
  }
  function compileStd(context) {
    let mainHandle = build(context, op => main(op));
    let trustingGuardedNonDynamicAppend = build(context, op => StdAppend(op, true, null));
    let cautiousGuardedNonDynamicAppend = build(context, op => StdAppend(op, false, null));
    let trustingGuardedDynamicAppend = build(context, op => StdAppend(op, true, trustingGuardedNonDynamicAppend));
    let cautiousGuardedDynamicAppend = build(context, op => StdAppend(op, false, cautiousGuardedNonDynamicAppend));
    return new StdLib(mainHandle, trustingGuardedDynamicAppend, cautiousGuardedDynamicAppend, trustingGuardedNonDynamicAppend, cautiousGuardedNonDynamicAppend);
  }
  const STDLIB_META = {
    evalSymbols: null,
    upvars: null,
    moduleName: 'stdlib',
    // TODO: ??
    scopeValues: null,
    isStrictMode: true,
    owner: null,
    size: 0
  };
  function build(program, builder) {
    let {
      constants,
      heap,
      resolver
    } = program;
    let encoder = new EncoderImpl(heap, STDLIB_META);
    function pushOp() {
      for (var _len = arguments.length, op = new Array(_len), _key = 0; _key < _len; _key++) {
        op[_key] = arguments[_key];
      }
      encodeOp(encoder, constants, resolver, STDLIB_META, op);
    }
    builder(pushOp);
    let result = encoder.commit(0);
    if (typeof result !== 'number') {
      // This shouldn't be possible
      throw new Error(`Unexpected errors compiling std`);
    } else {
      return result;
    }
  }
  class CompileTimeCompilationContextImpl {
    constructor(_ref21, resolver, createOp) {
      let {
        constants,
        heap
      } = _ref21;
      this.constants = void 0;
      this.heap = void 0;
      this.stdlib = void 0;
      this.resolver = resolver;
      this.createOp = createOp;
      this.constants = constants;
      this.heap = heap;
      this.stdlib = compileStd(this);
    }
  }
  _exports.CompileTimeCompilationContextImpl = CompileTimeCompilationContextImpl;
  function programCompilationContext(artifacts, resolver, createOp) {
    return new CompileTimeCompilationContextImpl(artifacts, resolver, createOp);
  }
  function templateCompilationContext(program, meta) {
    let encoder = new EncoderImpl(program.heap, meta, program.stdlib);
    return {
      program,
      encoder,
      meta
    };
  }
  const STATEMENTS = new Compilers();
  const INFLATE_ATTR_TABLE = ['class', 'id', 'value', 'name', 'type', 'style', 'href'];
  const INFLATE_TAG_TABLE = ['div', 'span', 'p', 'a'];
  function inflateTagName(tagName) {
    return typeof tagName === 'string' ? tagName : INFLATE_TAG_TABLE[tagName];
  }
  function inflateAttrName(attrName) {
    return typeof attrName === 'string' ? attrName : INFLATE_ATTR_TABLE[attrName];
  }
  STATEMENTS.add(_wireFormat.SexpOpcodes.Comment, (op, sexp) => op(_vm.Op.Comment, sexp[1]));
  STATEMENTS.add(_wireFormat.SexpOpcodes.CloseElement, op => op(_vm.Op.CloseElement));
  STATEMENTS.add(_wireFormat.SexpOpcodes.FlushElement, op => op(_vm.Op.FlushElement));
  STATEMENTS.add(_wireFormat.SexpOpcodes.Modifier, (op, _ref22) => {
    let [, expression, positional, named] = _ref22;
    if (isGetFreeModifier(expression)) {
      op(HighLevelResolutionOpcodes.Modifier, expression, handle => {
        op(_vm.MachineOp.PushFrame);
        SimpleArgs(op, positional, named, false);
        op(_vm.Op.Modifier, handle);
        op(_vm.MachineOp.PopFrame);
      });
    } else {
      expr(op, expression);
      op(_vm.MachineOp.PushFrame);
      SimpleArgs(op, positional, named, false);
      op(_vm.Op.Dup, _vm.$fp, 1);
      op(_vm.Op.DynamicModifier);
      op(_vm.MachineOp.PopFrame);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.StaticAttr, (op, _ref23) => {
    let [, name, value, namespace] = _ref23;
    op(_vm.Op.StaticAttr, inflateAttrName(name), value, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.StaticComponentAttr, (op, _ref24) => {
    let [, name, value, namespace] = _ref24;
    op(_vm.Op.StaticComponentAttr, inflateAttrName(name), value, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.DynamicAttr, (op, _ref25) => {
    let [, name, value, namespace] = _ref25;
    expr(op, value);
    op(_vm.Op.DynamicAttr, inflateAttrName(name), false, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.TrustingDynamicAttr, (op, _ref26) => {
    let [, name, value, namespace] = _ref26;
    expr(op, value);
    op(_vm.Op.DynamicAttr, inflateAttrName(name), true, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.ComponentAttr, (op, _ref27) => {
    let [, name, value, namespace] = _ref27;
    expr(op, value);
    op(_vm.Op.ComponentAttr, inflateAttrName(name), false, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.TrustingComponentAttr, (op, _ref28) => {
    let [, name, value, namespace] = _ref28;
    expr(op, value);
    op(_vm.Op.ComponentAttr, inflateAttrName(name), true, namespace ?? null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.OpenElement, (op, _ref29) => {
    let [, tag] = _ref29;
    op(_vm.Op.OpenElement, inflateTagName(tag));
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.OpenElementWithSplat, (op, _ref30) => {
    let [, tag] = _ref30;
    op(_vm.Op.PutComponentOperations);
    op(_vm.Op.OpenElement, inflateTagName(tag));
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Component, (op, _ref31) => {
    let [, expr, elementBlock, named, blocks] = _ref31;
    if (isGetFreeComponent(expr)) {
      op(HighLevelResolutionOpcodes.Component, expr, component => {
        InvokeComponent(op, component, elementBlock, null, named, blocks);
      });
    } else {
      // otherwise, the component name was an expression, so resolve the expression
      // and invoke it as a dynamic component
      InvokeDynamicComponent(op, expr, elementBlock, null, named, blocks, true, true);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Yield, (op, _ref32) => {
    let [, to, params] = _ref32;
    return YieldBlock(op, to, params);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.AttrSplat, (op, _ref33) => {
    let [, to] = _ref33;
    return YieldBlock(op, to, null);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Debugger, (op, _ref34) => {
    let [, debugInfo] = _ref34;
    return op(_vm.Op.Debugger, debugSymbolsOperand(), debugInfo);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Append, (op, _ref35) => {
    let [, value] = _ref35;
    // Special case for static values
    if (!Array.isArray(value)) {
      op(_vm.Op.Text, value === null || value === undefined ? '' : String(value));
    } else if (isGetFreeOptionalComponentOrHelper(value)) {
      op(HighLevelResolutionOpcodes.OptionalComponentOrHelper, value, {
        ifComponent(component) {
          InvokeComponent(op, component, null, null, null, null);
        },
        ifHelper(handle) {
          op(_vm.MachineOp.PushFrame);
          Call(op, handle, null, null);
          op(_vm.MachineOp.InvokeStatic, stdlibOperand('cautious-non-dynamic-append'));
          op(_vm.MachineOp.PopFrame);
        },
        ifValue(handle) {
          op(_vm.MachineOp.PushFrame);
          op(_vm.Op.ConstantReference, handle);
          op(_vm.MachineOp.InvokeStatic, stdlibOperand('cautious-non-dynamic-append'));
          op(_vm.MachineOp.PopFrame);
        }
      });
    } else if (value[0] === _wireFormat.SexpOpcodes.Call) {
      let [, expression, positional, named] = value;
      if (isGetFreeComponentOrHelper(expression)) {
        op(HighLevelResolutionOpcodes.ComponentOrHelper, expression, {
          ifComponent(component) {
            InvokeComponent(op, component, null, positional, hashToArgs(named), null);
          },
          ifHelper(handle) {
            op(_vm.MachineOp.PushFrame);
            Call(op, handle, positional, named);
            op(_vm.MachineOp.InvokeStatic, stdlibOperand('cautious-non-dynamic-append'));
            op(_vm.MachineOp.PopFrame);
          }
        });
      } else {
        SwitchCases(op, () => {
          expr(op, expression);
          op(_vm.Op.DynamicContentType);
        }, when => {
          when(_vm.ContentType.Component, () => {
            op(_vm.Op.ResolveCurriedComponent);
            op(_vm.Op.PushDynamicComponentInstance);
            InvokeNonStaticComponent(op, {
              capabilities: true,
              elementBlock: null,
              positional,
              named,
              atNames: false,
              blocks: namedBlocks(null)
            });
          });
          when(_vm.ContentType.Helper, () => {
            CallDynamic(op, positional, named, () => {
              op(_vm.MachineOp.InvokeStatic, stdlibOperand('cautious-non-dynamic-append'));
            });
          });
        });
      }
    } else {
      op(_vm.MachineOp.PushFrame);
      expr(op, value);
      op(_vm.MachineOp.InvokeStatic, stdlibOperand('cautious-append'));
      op(_vm.MachineOp.PopFrame);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.TrustingAppend, (op, _ref36) => {
    let [, value] = _ref36;
    if (!Array.isArray(value)) {
      op(_vm.Op.Text, value === null || value === undefined ? '' : String(value));
    } else {
      op(_vm.MachineOp.PushFrame);
      expr(op, value);
      op(_vm.MachineOp.InvokeStatic, stdlibOperand('trusting-append'));
      op(_vm.MachineOp.PopFrame);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Block, (op, _ref37) => {
    let [, expr, positional, named, blocks] = _ref37;
    if (isGetFreeComponent(expr)) {
      op(HighLevelResolutionOpcodes.Component, expr, component => {
        InvokeComponent(op, component, null, positional, hashToArgs(named), blocks);
      });
    } else {
      InvokeDynamicComponent(op, expr, null, positional, named, blocks, false, false);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.InElement, (op, _ref38) => {
    let [, block, guid, destination, insertBefore] = _ref38;
    ReplayableIf(op, () => {
      expr(op, guid);
      if (insertBefore === undefined) {
        PushPrimitiveReference(op, undefined);
      } else {
        expr(op, insertBefore);
      }
      expr(op, destination);
      op(_vm.Op.Dup, _vm.$sp, 0);
      return 4;
    }, () => {
      op(_vm.Op.PushRemoteElement);
      InvokeStaticBlock(op, block);
      op(_vm.Op.PopRemoteElement);
    });
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.If, (op, _ref39) => {
    let [, condition, block, inverse] = _ref39;
    return ReplayableIf(op, () => {
      expr(op, condition);
      op(_vm.Op.ToBoolean);
      return 1;
    }, () => {
      InvokeStaticBlock(op, block);
    }, inverse ? () => {
      InvokeStaticBlock(op, inverse);
    } : undefined);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Each, (op, _ref40) => {
    let [, value, key, block, inverse] = _ref40;
    return Replayable(op, () => {
      if (key) {
        expr(op, key);
      } else {
        PushPrimitiveReference(op, null);
      }
      expr(op, value);
      return 2;
    }, () => {
      op(_vm.Op.EnterList, labelOperand('BODY'), labelOperand('ELSE'));
      op(_vm.MachineOp.PushFrame);
      op(_vm.Op.Dup, _vm.$fp, 1);
      op(_vm.MachineOp.ReturnTo, labelOperand('ITER'));
      op(HighLevelBuilderOpcodes.Label, 'ITER');
      op(_vm.Op.Iterate, labelOperand('BREAK'));
      op(HighLevelBuilderOpcodes.Label, 'BODY');
      InvokeStaticBlockWithStack(op, block, 2);
      op(_vm.Op.Pop, 2);
      op(_vm.MachineOp.Jump, labelOperand('FINALLY'));
      op(HighLevelBuilderOpcodes.Label, 'BREAK');
      op(_vm.MachineOp.PopFrame);
      op(_vm.Op.ExitList);
      op(_vm.MachineOp.Jump, labelOperand('FINALLY'));
      op(HighLevelBuilderOpcodes.Label, 'ELSE');
      if (inverse) {
        InvokeStaticBlock(op, inverse);
      }
    });
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.With, (op, _ref41) => {
    let [, value, block, inverse] = _ref41;
    ReplayableIf(op, () => {
      expr(op, value);
      op(_vm.Op.Dup, _vm.$sp, 0);
      op(_vm.Op.ToBoolean);
      return 2;
    }, () => {
      InvokeStaticBlockWithStack(op, block, 1);
    }, () => {
      if (inverse) {
        InvokeStaticBlock(op, inverse);
      }
    });
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.Let, (op, _ref42) => {
    let [, positional, block] = _ref42;
    let count = CompilePositional(op, positional);
    InvokeStaticBlockWithStack(op, block, count);
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.WithDynamicVars, (op, _ref43) => {
    let [, named, block] = _ref43;
    if (named) {
      let [names, expressions] = named;
      CompilePositional(op, expressions);
      DynamicScope(op, names, () => {
        InvokeStaticBlock(op, block);
      });
    } else {
      InvokeStaticBlock(op, block);
    }
  });
  STATEMENTS.add(_wireFormat.SexpOpcodes.InvokeComponent, (op, _ref44) => {
    let [, expr, positional, named, blocks] = _ref44;
    if (isGetFreeComponent(expr)) {
      op(HighLevelResolutionOpcodes.Component, expr, component => {
        InvokeComponent(op, component, null, positional, hashToArgs(named), blocks);
      });
    } else {
      InvokeDynamicComponent(op, expr, null, positional, named, blocks, false, false);
    }
  });
  function hashToArgs(hash) {
    if (hash === null) return null;
    let names = hash[0].map(key => `@${key}`);
    return [names, hash[1]];
  }
  const PLACEHOLDER_HANDLE = -1;
  class CompilableTemplateImpl {
    constructor(statements, meta,
    // Part of CompilableTemplate
    symbolTable,
    // Used for debugging
    moduleName) {
      if (moduleName === void 0) {
        moduleName = 'plain block';
      }
      this.compiled = null;
      this.statements = statements;
      this.meta = meta;
      this.symbolTable = symbolTable;
      this.moduleName = moduleName;
    }

    // Part of CompilableTemplate
    compile(context) {
      return maybeCompile(this, context);
    }
  }
  function compilable(layout, moduleName) {
    let [statements, symbols, hasEval] = layout.block;
    return new CompilableTemplateImpl(statements, meta(layout), {
      symbols,
      hasEval
    }, moduleName);
  }
  function maybeCompile(compilable, context) {
    if (compilable.compiled !== null) return compilable.compiled;
    compilable.compiled = PLACEHOLDER_HANDLE;
    let {
      statements,
      meta
    } = compilable;
    let result = compileStatements(statements, meta, context);
    compilable.compiled = result;
    return result;
  }
  function compileStatements(statements, meta, syntaxContext) {
    let sCompiler = STATEMENTS;
    let context = templateCompilationContext(syntaxContext, meta);
    let {
      encoder,
      program: {
        constants,
        resolver
      }
    } = context;
    function pushOp() {
      for (var _len2 = arguments.length, op = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        op[_key2] = arguments[_key2];
      }
      encodeOp(encoder, constants, resolver, meta, op);
    }
    for (const statement of statements) {
      sCompiler.compile(pushOp, statement);
    }
    let handle = context.encoder.commit(meta.size);
    return handle;
  }
  function compilableBlock(block, containing) {
    return new CompilableTemplateImpl(block[0], containing, {
      parameters: block[1] || _util.EMPTY_ARRAY
    });
  }
  const DEFAULT_CAPABILITIES = _exports.DEFAULT_CAPABILITIES = {
    dynamicLayout: true,
    dynamicTag: true,
    prepareArgs: true,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    dynamicScope: true,
    createCaller: false,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false,
    hasSubOwner: false
  };
  const MINIMAL_CAPABILITIES = _exports.MINIMAL_CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    dynamicScope: false,
    createCaller: false,
    updateHook: false,
    createInstance: false,
    wrapped: false,
    willDestroy: false,
    hasSubOwner: false
  };
  class WrappedBuilder {
    constructor(layout, moduleName) {
      this.symbolTable = void 0;
      this.compiled = null;
      this.attrsBlockNumber = void 0;
      this.layout = layout;
      this.moduleName = moduleName;
      let {
        block
      } = layout;
      let [, symbols, hasEval] = block;
      symbols = symbols.slice();

      // ensure ATTRS_BLOCK is always included (only once) in the list of symbols
      let attrsBlockIndex = symbols.indexOf(ATTRS_BLOCK);
      if (attrsBlockIndex === -1) {
        this.attrsBlockNumber = symbols.push(ATTRS_BLOCK);
      } else {
        this.attrsBlockNumber = attrsBlockIndex + 1;
      }
      this.symbolTable = {
        hasEval,
        symbols
      };
    }
    compile(syntax) {
      if (this.compiled !== null) return this.compiled;
      let m = meta(this.layout);
      let context = templateCompilationContext(syntax, m);
      let {
        encoder,
        program: {
          constants,
          resolver
        }
      } = context;
      function pushOp() {
        for (var _len3 = arguments.length, op = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          op[_key3] = arguments[_key3];
        }
        encodeOp(encoder, constants, resolver, m, op);
      }
      WrappedComponent(pushOp, this.layout, this.attrsBlockNumber);
      let handle = context.encoder.commit(m.size);
      if (typeof handle !== 'number') {
        return handle;
      }
      this.compiled = handle;
      return handle;
    }
  }
  _exports.WrappedBuilder = WrappedBuilder;
  let clientId = 0;
  let templateCacheCounters = _exports.templateCacheCounters = {
    cacheHit: 0,
    cacheMiss: 0
  };

  // These interfaces are for backwards compatibility, some addons use these intimate APIs

  /**
   * Wraps a template js in a template module to change it into a factory
   * that handles lazy parsing the template and to create per env singletons
   * of the template.
   */
  function templateFactory(_ref45) {
    let {
      id: templateId,
      moduleName,
      block,
      scope,
      isStrictMode
    } = _ref45;
    // TODO(template-refactors): This should be removed in the near future, as it
    // appears that id is unused. It is currently kept for backwards compat reasons.
    let id = templateId || `client-${clientId++}`;

    // TODO: This caches JSON serialized output once in case a template is
    // compiled by multiple owners, but we haven't verified if this is actually
    // helpful. We should benchmark this in the future.
    let parsedBlock;
    let ownerlessTemplate = null;
    let templateCache = new WeakMap();
    let factory = owner => {
      if (parsedBlock === undefined) {
        parsedBlock = JSON.parse(block);
      }
      if (owner === undefined) {
        if (ownerlessTemplate === null) {
          templateCacheCounters.cacheMiss++;
          ownerlessTemplate = new TemplateImpl({
            id,
            block: parsedBlock,
            moduleName,
            owner: null,
            scope,
            isStrictMode
          });
        } else {
          templateCacheCounters.cacheHit++;
        }
        return ownerlessTemplate;
      }
      let result = templateCache.get(owner);
      if (result === undefined) {
        templateCacheCounters.cacheMiss++;
        result = new TemplateImpl({
          id,
          block: parsedBlock,
          moduleName,
          owner,
          scope,
          isStrictMode
        });
        templateCache.set(owner, result);
      } else {
        templateCacheCounters.cacheHit++;
      }
      return result;
    };
    factory.__id = id;
    factory.__meta = {
      moduleName
    };
    return factory;
  }
  class TemplateImpl {
    constructor(parsedLayout) {
      this.result = 'ok';
      this.layout = null;
      this.wrappedLayout = null;
      this.parsedLayout = parsedLayout;
    }
    get moduleName() {
      return this.parsedLayout.moduleName;
    }
    get id() {
      return this.parsedLayout.id;
    }

    // TODO(template-refactors): This should be removed in the near future, it is
    // only being exposed for backwards compatibility
    get referrer() {
      return {
        moduleName: this.parsedLayout.moduleName,
        owner: this.parsedLayout.owner
      };
    }
    asLayout() {
      if (this.layout) return this.layout;
      return this.layout = compilable((0, _util.assign)({}, this.parsedLayout), this.moduleName);
    }
    asWrappedLayout() {
      if (this.wrappedLayout) return this.wrappedLayout;
      return this.wrappedLayout = new WrappedBuilder((0, _util.assign)({}, this.parsedLayout), this.moduleName);
    }
  }
});