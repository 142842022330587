define("active-model-adapter/active-model-serializer", ["exports", "@ember-data/serializer/rest", "@ember-data/store", "ember-inflector", "@ember/string", "@ember/service", "@ember/utils"], function (_exports, _rest, _store, _emberInflector, _string, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    @module ember-data
   */
  /**
    The ActiveModelSerializer is a subclass of the RESTSerializer designed to integrate
    with a JSON API that uses an underscored naming convention instead of camelCasing.
    It has been designed to work out of the box with the
    [active\_model\_serializers](http://github.com/rails-api/active_model_serializers)
    Ruby gem. This Serializer expects specific settings using ActiveModel::Serializers,
    `embed :ids, embed_in_root: true` which sideloads the records.
  
    This serializer extends the DS.RESTSerializer by making consistent
    use of the camelization, decamelization and pluralization methods to
    normalize the serialized JSON into a format that is compatible with
    a conventional Rails backend and Ember Data.
  
    ## JSON Structure
  
    The ActiveModelSerializer expects the JSON returned from your server
    to follow the REST adapter conventions substituting underscored keys
    for camelcased ones.
  
    ### Conventional Names
  
    Attribute names in your JSON payload should be the underscored versions of
    the attributes in your Ember.js models.
  
    For example, if you have a `Person` model:
  
    ```javascript
    export default class Person extends Model {
      @attr() firstName;
      @attr() lastName;
      @belongsTo('occupation') occupation;
    }
    ```
  
    The JSON returned should look like this:
  
    ```json
    {
      "famous_person": {
        "id": 1,
        "first_name": "Barack",
        "last_name": "Obama",
        "occupation": "President"
      }
    }
    ```
  
    Let's imagine that `Occupation` is just another model:
  
    ```javascript
    export default class Person extends Model {
      @attr() firstName;
      @attr() lastName;
      @belongsTo('occupation') occupation;
    }
  
    export default class Occupation extends Model {
      @attr() name;
      @attr('number') salary;
      @hasMany('person') people;
    }
    ```
  
    The JSON needed to avoid extra server calls, should look like this:
  
    ```json
    {
      "people": [{
        "id": 1,
        "first_name": "Barack",
        "last_name": "Obama",
        "occupation_id": 1
      }],
  
      "occupations": [{
        "id": 1,
        "name": "President",
        "salary": 100000,
        "person_ids": [1]
      }]
    }
    ```
  */
  let ActiveModelSerializer = _exports.default = (_class = class ActiveModelSerializer extends _rest.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    // SERIALIZE

    /**
      Converts camelCased attributes to underscored when serializing.
    */
    keyForAttribute(attr) {
      return (0, _string.decamelize)(attr);
    }

    /**
      Underscores relationship names and appends "_id" or "_ids" when serializing
      relationship keys.
    */
    keyForRelationship(relationshipModelName, kind) {
      const key = (0, _string.decamelize)(relationshipModelName);
      if (kind === 'belongsTo') {
        return key + '_id';
      } else if (kind === 'hasMany') {
        return (0, _emberInflector.singularize)(key) + '_ids';
      } else {
        return key;
      }
    }

    /**
     `keyForLink` can be used to define a custom key when deserializing link
     properties. The `ActiveModelSerializer` camelizes link keys by default.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    keyForLink(key, _relationshipKind) {
      return (0, _string.camelize)(key);
    }

    /*
      Does not serialize hasMany relationships by default.
    */
    // eslint-disable-next-line
    serializeHasMany() {}

    /**
     Underscores the JSON root keys when serializing.
    */
    payloadKeyFromModelName(modelName) {
      return (0, _string.underscore)((0, _string.decamelize)(modelName));
    }

    /**
      Serializes a polymorphic type as a fully capitalized model name.
    */
    serializePolymorphicType(snapshot, json, relationship) {
      const key = relationship.key;
      const belongsTo = snapshot.belongsTo(key);
      const jsonKey = (0, _string.underscore)(key + '_type');
      if ((0, _utils.isNone)(belongsTo)) {
        json[jsonKey] = null;
      } else {
        json[jsonKey] = (0, _string.classify)(belongsTo.modelName).replace('/', '::');
      }
    }

    /**
      Add extra step to `DS.RESTSerializer.normalize` so links are normalized.
       If your payload looks like:
       ```json
      {
        "post": {
          "id": 1,
          "title": "Rails is omakase",
          "links": { "flagged_comments": "api/comments/flagged" }
        }
      }
      ```
       The normalized version would look like this
       ```json
      {
        "post": {
          "id": 1,
          "title": "Rails is omakase",
          "links": { "flaggedComments": "api/comments/flagged" }
        }
      }
      ```
    */
    normalize(typeClass, hash, prop) {
      this.normalizeLinks(hash);
      return super.normalize(typeClass, hash, prop);
    }

    /**
      Convert `snake_cased` links  to `camelCase`
    */

    // eslint-disable-next-line
    normalizeLinks(data) {
      if (data.links) {
        const links = data.links;
        for (const link in links) {
          const camelizedLink = (0, _string.camelize)(link);
          if (camelizedLink !== link) {
            links[camelizedLink] = links[link];
            delete links[link];
          }
        }
      }
    }

    /**
     * @private
     */
    _keyForIDLessRelationship(key, relationshipType) {
      if (relationshipType === 'hasMany') {
        return (0, _string.underscore)((0, _emberInflector.pluralize)(key));
      } else {
        return (0, _string.underscore)((0, _emberInflector.singularize)(key));
      }
    }
    extractRelationships(modelClass, resourceHash) {
      modelClass.eachRelationship(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (key, relationshipMeta) => {
        const relationshipKey = this.keyForRelationship(key, relationshipMeta.kind);
        const idLessKey = this._keyForIDLessRelationship(key, relationshipMeta.kind);

        // converts post to post_id, posts to post_ids
        if (resourceHash[idLessKey] && typeof relationshipMeta[relationshipKey] === 'undefined') {
          resourceHash[relationshipKey] = resourceHash[idLessKey];
        }

        // prefer the format the AMS gem expects, e.g.:
        // relationship: {id: id, type: type}
        if (relationshipMeta.options.polymorphic) {
          extractPolymorphicRelationships(key, relationshipMeta, resourceHash, relationshipKey);
        }
        // If the preferred format is not found, use {relationship_name_id, relationship_name_type}
        if (Object.prototype.hasOwnProperty.call(resourceHash, relationshipKey) && typeof resourceHash[relationshipKey] !== 'object') {
          const polymorphicTypeKey = this.keyForRelationship(key) + '_type';
          if (resourceHash[polymorphicTypeKey] && relationshipMeta.options.polymorphic) {
            const id = resourceHash[relationshipKey];
            const type = resourceHash[polymorphicTypeKey];
            delete resourceHash[polymorphicTypeKey];
            delete resourceHash[relationshipKey];
            resourceHash[relationshipKey] = {
              id: id,
              type: type
            };
          }
        }
      }, this);
      return super.extractRelationships(modelClass, resourceHash);
    }
    modelNameFromPayloadKey(key) {
      const convertedFromRubyModule = (0, _emberInflector.singularize)(key.replace('::', '/'));
      return (0, _store.normalizeModelName)(convertedFromRubyModule);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  function extractPolymorphicRelationships(key,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _relationshipMeta,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resourceHash, relationshipKey) {
    const polymorphicKey = (0, _string.decamelize)(key);
    const hash = resourceHash[polymorphicKey];
    if (hash !== null && typeof hash === 'object') {
      resourceHash[relationshipKey] = hash;
    }
  }
});