(function() {
  /* globals define */
  'use strict';

  function initSentry(config) {
    var d = document;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://browser.sentry-cdn.com/6.19.7/bundle.min.js';
    s.setAttribute('integrity', 'sha384-KXjn4K+AYjp1cparCXazrB+5HKdi69IUYz8glD3ySH3fnDgMX3Wg6VTMvXUGr4KB');
    s.setAttribute('crossorigin', 'anonymous');
    s.addEventListener('load', () => {
      if (window.Sentry && typeof window.Sentry.init === 'function') {
        window.Sentry.init(config);
      }
    }, false);
    s.addEventListener('error', () => {
      console.log('Could not load getsentry SDK');
    }, false);

    var x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  function generateModule(name, values) {
    define(name, [], function() {
      'use strict'; // jshint ignore:line

      return values;
    });
  }

  generateModule('sentry', {
    configureScope: function () {
      if (window.Sentry && typeof window.Sentry.configureScope === 'function') {
        window.Sentry.configureScope(...arguments);
      }
    },
    captureMessage: function () {
      if (window.Sentry && typeof window.Sentry.captureMessage === 'function') {
        window.Sentry.captureMessage(...arguments);
      } else {
        console.error(...arguments);
      }
    },
    captureException: function () {
      if (window.Sentry && typeof window.Sentry.captureException === 'function') {
        window.Sentry.captureException(...arguments);
      } else {
        console.error(...arguments);
      }
    },
    withScope: function () {
      if (window.Sentry && typeof window.Sentry.withScope === 'function') {
        window.Sentry.withScope(...arguments);
      }
    },
    initSentry: initSentry
  });
})();
